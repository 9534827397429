
import { computed, defineComponent, reactive, Ref, ref, UnwrapRef, onMounted, createVNode } from 'vue'
import { CheckOutlined, EditOutlined, CloseOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { cloneDeep } from 'lodash-es'
import { notification, Modal } from 'ant-design-vue'

import planService from '../../../services/plan'

interface DataItem {
  _id?: string;
  limit_from: number;
  limit_to: number;
  price: number;
  plan?: string;
}
interface Plan {
  _id?: string;
  title: string;
  description: string;
  type: string;
  details?: [];
}

export default defineComponent({
  components: {
    CheckOutlined,
    EditOutlined,
    CloseOutlined,
    DeleteOutlined
  },
  props: ['clientId'],
  setup (props) {
    const columns = [
      {
        title: 'Count',
        children: [
          {
            title: 'From',
            dataIndex: 'limit_from',
            key: 'from',
            width: '27%',
            slots: { customRender: 'limit_from' }
          },
          {
            title: 'To',
            dataIndex: 'limit_to',
            width: '27%',
            key: 'to',
            slots: { customRender: 'limit_to' }
          }
        ]
      },
      {
        title: 'Price Per Unit',
        children: [
          {
            dataIndex: 'price',
            width: '27%',
            slots: { customRender: 'price' }
          },
          {
            dataIndex: 'operation',
            width: '19%',
            slots: { customRender: 'operation' }
          }
        ]
      }
    ]
    const plans = ref<Array<Plan>>([])
    const disableAddSub = ref<boolean>(false)
    const disableAddCrd = ref<boolean>(false)
    const edit = ref<boolean>(false)
    const dataSourceSub: Ref<DataItem[]> = ref([])
    const dataSourceCrd: Ref<DataItem[]> = ref([])
    const subscriptionId = ref<string>()
    const creditId = ref<string>()
    const countSub = computed(() => dataSourceSub.value.length + 1)
    const countCrd = computed(() => dataSourceCrd.value.length + 1)
    const editableDataSub: UnwrapRef<Record<string, DataItem>> = reactive({})
    const editableDataCrd: UnwrapRef<Record<string, DataItem>> = reactive({})
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const editSub = (key: string, status?: boolean) => {
      disableAddSub.value = true
      if (status) edit.value = true
      editableDataSub[key] = cloneDeep(dataSourceSub.value.filter(item => key === item._id)[0])
    }
    const editCrd = (key: string, status?: boolean) => {
      disableAddCrd.value = true
      if (status) edit.value = true
      editableDataCrd[key] = cloneDeep(dataSourceCrd.value.filter(item => key === item._id)[0])
    }
    const deletePlanList = async (id, index, type) => {
      try {
        if (props.clientId) {
          await planService.deleteClientPlanDetails(id)
        } else {
          await planService.deletePlanDetails(id)
        }
        if (type === 'sub') {
          dataSourceSub.value.splice(index, 1)
        }
        if (type === 'cred') {
          dataSourceCrd.value.splice(index, 1)
        }
        await notify('Success', 'Plan removed successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const deleteSubConfirmation = async (id, index, type) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to delete this record?. This process cannot be undo.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          await deletePlanList(id, index, type)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const addPlan = async (data, type) => {
      try {
        delete data._id
        if (type === 'credit') data.plan = creditId.value
        else data.plan = subscriptionId.value
        if (props.clientId) {
          data.profile = props.clientId
          await planService.addClientPlanDetails(data)
        } else {
          await planService.addPlanDetails(data)
        }
        await notify('Success', 'Plan details added successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const editPlan = async (data, type) => {
      try {
        if (type === 'credit') data.plan = creditId.value
        else data.plan = subscriptionId.value
        if (props.clientId) {
          await planService.updateClientPlanDetails(data._id, data)
        } else {
          await planService.updatePlanDetails(data._id, data)
        }
        await notify('Success', 'Plan details updated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const saveSub = async (key: string) => {
      const data = editableDataSub[key]
      if (data.limit_to && data.price > 0) {
        disableAddSub.value = false
      }
      Object.assign(dataSourceSub.value.filter(item => key === item._id)[0], editableDataSub[key])
      delete editableDataSub[key]
      if (edit.value) {
        editPlan(data, 'subscription')
      } else {
        addPlan(data, 'subscription')
      }
    }
    const saveCrd = async (key: string) => {
      const data = editableDataCrd[key]
      if (data.limit_to && data.price > 0) {
        disableAddCrd.value = false
      }
      Object.assign(dataSourceCrd.value.filter(item => key === item._id)[0], editableDataCrd[key])
      delete editableDataCrd[key]
      if (edit.value) {
        editPlan(data, 'credit')
      } else {
        addPlan(data, 'credit')
      }
    }
    const cancelSub = (key: string) => {
      const data = editableDataSub[key]
      delete editableDataSub[key]
      if (data.limit_to && data.price > 0) {
        disableAddSub.value = false
      }
    }
    const cancelCrd = (key: string) => {
      const data = editableDataCrd[key]
      delete editableDataCrd[key]
      if (data.limit_to && data.price > 0) {
        disableAddCrd.value = false
      }
    }
    const handleAddSub = async () => {
      const from = (dataSourceSub.value.length === 0 ? 1 : +dataSourceSub.value[dataSourceSub.value.length - 1].limit_to + 1)
      const newData = {
        _id: `${countSub.value}`,
        limit_from: from,
        limit_to: from + 9,
        price: 0
      }
      dataSourceSub.value.push(newData)
      disableAddSub.value = true
      editSub(newData._id, false)
    }
    const handleAddCrd = async () => {
      const from = (dataSourceCrd.value.length === 0 ? 1 : +dataSourceCrd.value[dataSourceCrd.value.length - 1].limit_to + 1)
      const newData = {
        _id: `${countCrd.value}`,
        limit_from: from,
        limit_to: from + 9,
        price: 0
      }
      dataSourceCrd.value.push(newData)
      disableAddCrd.value = true
      editCrd(newData._id, false)
    }
    const getPlans = async () => {
      try {
        const responce = await planService.getPlans()
        plans.value = responce.data
        const subscription = plans.value.filter(plan => plan.type === 'Subscription')
        const credit = plans.value.filter(plan => plan.type === 'Credits')
        dataSourceSub.value = subscription[0].details
        dataSourceCrd.value = credit[0].details
        subscriptionId.value = subscription[0]._id
        creditId.value = credit[0]._id
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getClientPlans = async () => {
      try {
        const responce = await planService.getClientPlans(props.clientId)
        plans.value = responce.data
        const subscription = plans.value.filter(plan => plan.type === 'Subscription')
        const credit = plans.value.filter(plan => plan.type === 'Credits')
        dataSourceSub.value = subscription[0].details
        dataSourceCrd.value = credit[0].details
        subscriptionId.value = subscription[0]._id
        creditId.value = credit[0]._id
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      if (props.clientId) {
        getClientPlans()
      } else {
        getPlans()
      }
    })
    return {
      columns,
      handleAddSub,
      dataSourceSub,
      editableDataSub,
      editSub,
      saveSub,
      cancelSub,
      handleAddCrd,
      dataSourceCrd,
      editableDataCrd,
      editCrd,
      saveCrd,
      cancelCrd,
      disableAddSub,
      disableAddCrd,
      deleteSubConfirmation
    }
  }
})
